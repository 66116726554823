import React, {useState, useEffect} from 'react'
import {navigate, Link} from 'gatsby'
import {Container, Header, Breadcrumb, Icon} from 'semantic-ui-react'
import Seo from '../../components/SEO'
import Layout from '../../components/Layout'
import * as styles from '../styles/pages.module.css'

import MyAccountHeader from '../../components/Pages/MyAccount/header'

import ChangePass from '../../components/Pages/MyAccount/changePass'
const Negosyo = require('../../../lib/negosyo')

const MyAccountPasswordPage = ({location}) => {
/*
	useEffect(() => {
		if (location.state) {
			if (location.state.customKey) {
				setLocationkey(location.state.customKey)
			}
		}
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===false) {
				navigate(
					`/sign-in/`,
					{
						replace:true,
						state: {
							customKey:locationkey
						}
					}
				)
			} else {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							setDetails(response.data);
						}
					}).catch((err)=>{
						//console.log(err)
					});
				}).catch((err)=>{
				});
			}
		})

	}, [])
*/

	return (
		<Layout location={location}>
			<Seo title={"Change Password"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<MyAccountHeader location={location} />
				<Container className={styles.container}>
					<Header className={styles.header}>Change Password</Header>
					<ChangePass/>
				</Container>
			</Container>
		</Layout>
	)
}

export default MyAccountPasswordPage
