import React, {Component} from "react"
import {Button, Form, Message, Transition } from 'semantic-ui-react'
import * as styles from '../styles/pages.module.css'
import {confirmationColor} from '../../../globalVar'

const Negosyo = require('../../../../lib/negosyo')

export default class changePassWithOldPass extends Component {
	state={
		password:'',
		confirmPassword:'',
		oldPassword:'',
		showPass:false,
		showCPass:false,
		showOPass:false,
		success: undefined,
		error:undefined,
		loading:false,
		errorMessage: {
			oldPassword:undefined,
			password:undefined,
			confirmPassword:undefined
		}
	}

	handleInputChange = (e, {name, value}) => {
		this.setState({ [name]: value })
		if(value==='') {
			const message = "this must not be blank"
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		} else {
			const message = null
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					[name] : message
				}
			}))
		}
	}


	validateInput = () => {
		let check = true;
		const { password, confirmPassword, oldPassword } = this.state
		const test = { password, confirmPassword, oldPassword}
		for( let key of Object.keys(test) ) {
			if(this.state[key]==='' ) {
				this.setState( prevState=>({
					errorMessage: {
						...prevState.errorMessage,
						[key]:"this must not be blank"
					}

				}))
				check = false
			}
		}
		if(oldPassword.length<6) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					oldPassword:"minimum password length is 6"
				}
			}))
			check = false;
		}
		const re = /^[\S]+.*[\S]+$/
		if (password.length<6) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					password:"password length must be greater than 5"
				}
			}))
			check = false;
		} else if (!re.test(password)) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					password:"password must not start or end with a space"
				}
			}))
			check=false
		} else if (password!==confirmPassword) {
			this.setState(prevState => ({
				errorMessage: {
					...prevState.errorMessage,
					confirmPassword:"Passwords must match"
				}
			}))
			check = false;
		}
		return check;
	}

	submitChangePassword = () => {
		const {oldPassword, password} = this.state

		if(this.validateInput()) {
			this.setState({loading:true})
			Negosyo.changePassword(oldPassword, password)
			.then((response)=>{
				if(response==="success") {
					this.setState({
						success:"Successfully Changed Password",
						error:undefined,
						loading:false,
						password:'',
						confirmPassword:'',
						oldPassword:''
					})
					setTimeout(()=>{
						this.setState({success:undefined})
					}, 5000)
				}

			}).catch((err)=>{
				if(err!=="Attempt limit exceeded, please try after some time." ) {
					this.setState({error:"Unable to Change password", loading:false})
				}else {
					this.setState({error:err, loading:false})
				}

			})
			//aws amplify change password
		}
	}

	toggleShow = (pass) => {
		this.setState({
			[pass]:!this.state[pass]
		})
	}

	render() {
		const {
			password,
			confirmPassword,
			oldPassword,
			showPass,
			showCPass,
			showOPass,
			errorMessage,
			error,
			success,
			loading
		} = this.state

		const {
			handleInputChange,
			toggleShow,
			submitChangePassword
		} = this

		let icon
		let icon2
		let icon3
		if(showPass) {
			icon="eye slash"
		} else {
			icon="eye"
		}
		if(showCPass) {
			icon2="eye slash"
		} else {
			icon2="eye"
		}
		if(showOPass) {
			icon3="eye slash"
		}else {
			icon3="eye"
		}
		return(
			<React.Fragment>

				<Form onSubmit={submitChangePassword}>
					<Form.Input
						label="Old Password"
						placeholder="your old password"
						type={showOPass ? undefined:"password"}
						error={errorMessage.oldPassword}
						value={oldPassword}
						name='oldPassword'
						onChange={handleInputChange}
						action={{
							icon:icon3,
							type:"button",
							onClick:()=>toggleShow("showOPass")
						}}
						className={styles.form}
					/>
					<Form.Input
						label="New Password"
						placeholder="minimum of 6 characters"
						type={showPass ? undefined:"password"}
						error={errorMessage.password}
						value={password}
						name='password'
						onChange={handleInputChange}
						action={{
							icon:icon,
							onClick: ()=>toggleShow("showPass"),
							type:"button"
						}}
						className={styles.form}
					/>
					<Form.Input
						label="Confirm New Password"
						placeholder="New Password"
						type={showCPass ? undefined:"password"}
						error={errorMessage.confirmPassword}
						value={confirmPassword}
						name='confirmPassword'
						onChange={handleInputChange}
						action={{
							icon: icon2,
							onClick: ()=>toggleShow("showCPass"),
							type:"button"
						}}
						className={styles.form}
					/>
					{error && (
						<Message negative className={styles.msg}>
							<Message.Header>{this.state.error}</Message.Header>
						</Message>
					)}
					{success && (
						<Transition duration={{hide: 500, show: 500}} visible={success ? true:false}>
							<Message positive className={styles.msg}>
								<Message.Header>Success</Message.Header>
								{this.state.success}
							</Message>
						</Transition>
					)}
					<Button
						color={confirmationColor}
						className={styles.form}
						loading={loading}
						disabled={loading}
					>
						Change Password
					</Button>
				</Form>
			</React.Fragment>
		)
	}
}




